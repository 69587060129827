export const siteName = "Özpaş Sanal Market";
export const siteNameUpper = "ÖZPAŞ SANAL MARKET";
export const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=com.marketyo.ozpas&hl=tr";
export const appleStoreUrl =
  "https://apps.apple.com/tr/app/ozpas-market/id1462632469?l=tr";
export const siteMailList = [
  {
    mail: "bilgi@ozpasmarket.com",
    subject: "Bize Mail Bırakın",
  },
];
export const sitePhoneList = [{ phone: "0 (264) 211 31 00", info: "" }];
export const eklenenMiktarKatsayisi = 0.1;
/* ============================================================================================== */
/*                            appstora yonlendirme mesaji ile ilgili                              */
/* ============================================================================================== */
export const askPlatformMessage = true; // yonlendirme sorulacak mi
export const askPlatformCounter = 5; // her kac giriste sorulacak (0 ve 1 icin surekli sorar)
export const useAskNeverButton = false; // bir daha sorma butonu aktif mi
/* ============================================================================================== */
/*                                  kategori side bar ile ilgili                                  */
/* ============================================================================================== */
export const categorySideBarType = 1; //1 yeni versiyon A101 ornegi, degilse orjinal versiyon
/* ============================================================================================== */
/*                                        harita ile ilgili                                       */
/* ============================================================================================== */
export const mapCoordinate = {
  lat: 41.0838,
  lng: 28.96385,
};
export const mapZoom = 12;
